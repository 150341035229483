import { template as template_2721ac2c262f423fa6c0811fc485b490 } from "@ember/template-compiler";
import ClassicComponent from "@ember/component";
const empty = template_2721ac2c262f423fa6c0811fc485b490(`
  {{! template-lint-disable no-yield-only}}{{yield}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const shortcuts = {
    div: template_2721ac2c262f423fa6c0811fc485b490(`<div ...attributes>{{yield}}</div>`, {
        eval () {
            return eval(arguments[0]);
        }
    }),
    span: template_2721ac2c262f423fa6c0811fc485b490(`<span ...attributes>{{yield}}</span>`, {
        eval () {
            return eval(arguments[0]);
        }
    }),
    form: template_2721ac2c262f423fa6c0811fc485b490(`<form ...attributes>{{yield}}</form>`, {
        eval () {
            return eval(arguments[0]);
        }
    }),
    a: template_2721ac2c262f423fa6c0811fc485b490(`<a ...attributes>{{yield}}</a>`, {
        eval () {
            return eval(arguments[0]);
        }
    }),
    button: template_2721ac2c262f423fa6c0811fc485b490(`<button ...attributes>{{yield}}</button>`, {
        eval () {
            return eval(arguments[0]);
        }
    }),
    td: template_2721ac2c262f423fa6c0811fc485b490(`<td ...attributes>{{yield}}</td>`, {
        eval () {
            return eval(arguments[0]);
        }
    })
};
/**
 * Returns a wrapper component with the given tagname, or an empty wrapper for an empty string.
 * Similar to the reference implementation of RFC389, with higher-performance shortcuts for common elements.
 *
 * Can be used directly in a template:
 *
 * ```hbs
 * {{#let (element @tagName) as |Wrapper|}}
 *   <Wrapper class="pt-10 pb-10 ps-20 box-shadow" ...attributes>
 *     Content
 *   </Wrapper>
 * {{/let}}
 * ```
 *
 * Or in js:
 *
 * ```gjs
 * class MyComponent {
 *   get wrapper(){
 *     return element(this.args.tagName);
 *   }
 *
 *   <template>
 *     <this.wrapper>
 *       Content
 *     </this.wrapper>
 *   </template>
 * }
 * ```
 */ export default function element(tagName) {
    if (typeof tagName !== "string") {
        throw new Error(`element helper only accepts string literals, you passed ${tagName}`);
    }
    if (tagName === null || tagName === undefined) {
        return null;
    } else if (tagName === "") {
        return empty;
    } else if (shortcuts[tagName]) {
        return shortcuts[tagName];
    } else {
        return template_2721ac2c262f423fa6c0811fc485b490(`
      <ClassicComponent
        @tagName={{tagName}}
        ...attributes
      >{{yield}}</ClassicComponent>
    `, {
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
