import { template as template_832540fcafe049fca32ab1e54402e4f1 } from "@ember/template-compiler";
const FKLabel = template_832540fcafe049fca32ab1e54402e4f1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
